:root {
  --primary: #4F99A5;
  --secondary: #969696;
  --yellow: #FFD351;
}

#app {
  /* background-color: #fff;
  background-image: linear-gradient(30deg, #4F99A5 12%, transparent 12.5%, transparent 87%, #4F99A5 87.5%, #4F99A5), linear-gradient(150deg, #4F99A5 12%, transparent 12.5%, transparent 87%, #4F99A5 87.5%, #4F99A5), linear-gradient(30deg, #4F99A5 12%, transparent 12.5%, transparent 87%, #4F99A5 87.5%, #4F99A5), linear-gradient(150deg, #4F99A5 12%, transparent 12.5%, transparent 87%, #4F99A5 87.5%, #4F99A5), linear-gradient(60deg, #4F99A577 25%, transparent 25.5%, transparent 75%, #4F99A577 75%, #4F99A577), linear-gradient(60deg, #4F99A577 25%, transparent 25.5%, transparent 75%, #4F99A577 75%, #4F99A577);
  background-size: 48px 84px;
  background-position: 0 0, 0 0, 24px 42px, 24px 42px, 0 0, 24px 42px; */
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: #CEE0E0;
  /* background-image: url('../public/assets/logo/logo-zalm-rand-bg.png');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 300px 300px; */
}

h1 {
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;
}

.MuiContainer-root {
  background-color: #CEE0E0;
  /*rgba(255, 255, 255, 0.9);*/
}


/* NAVIGATION */
nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 10px 10px 8px #00000010;
  z-index: 999;
}

nav ul {
  list-style: none;
  text-align: center;
  padding: 0;
  margin: 0;
}

nav ul li {
  height: 60px;
  display: inline-block;
  width: 200px;
}

nav ul li a {
  display: block !important;
  padding: 20px !important;
  text-decoration: none !important;
  color: #333 !important;
  font-weight: 800 !important;
  text-transform: uppercase !important;
}

nav ul li a,
nav ul li a:after,
nav ul li a:before {
  height: 20px;
  transition: all .5s;
}

nav.fill ul li a {
  position: relative;
  background-color: attr('data-color')
}

nav.fill ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #fff;
  height: 1px;
}

nav.fill ul li a {
  transition: all 2s;
}

nav.fill ul li a:after {
  text-align: left;
  content: '.';
  margin: 0;
  opacity: 0;
}

nav.fill ul li a.selected,
nav.fill ul li a:hover {
  color: var(--fill-text-color) !important;
  z-index: 1;
}

nav.fill ul li a.selected:after,
nav.fill ul li a:not(.selected):hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}

@keyframes fill {
  0% {
    width: 100%;
    height: 0px;
  }

  100% {
    width: 100%;
    height: 100%;
    background: var(--fill-color);
  }
}

.menu {
  display: none;
}

#nav-spacer {
  height: 60px;
  background-color: #fff;
}

@media only screen and (max-width: 1199px) {
  #nav-spacer {
    height: 120px;
  }
}

#nav-logo {
  display: none;
  transition: all 0.5s;
}

@media only screen and (max-width: 599px) {
  #nav-spacer {
    height: 80px;
    box-shadow: 10px 10px 8px #00000010;
  }

  .menu {
    display: block;
  }

  nav {
    height: 0;
    overflow-y: scroll;
    transition-duration: 0.5s;
  }

  nav.open {
    height: 100vh;
  }

  nav ul li {
    width: 100%;
  }

  #nav-logo {
    display: block;
  }
}

.menu {
  z-index: 1000;
  padding: 10px;
  position: fixed;
  width: 60px;
  height: 60px;
  top: 0px;
  right: 0px;
  transition-duration: 0.5s;
  rotate: 180deg;
  scale: .7;
}

.menu .icon {
  transition-duration: 0.5s;
  position: absolute;
  height: 8px;
  width: 60px;
  top: 35px;
  left: 10px;
  background-color: #212121;
}

.menu .icon:before {
  transition-duration: 0.5s;
  position: absolute;
  width: 60px;
  height: 8px;
  background-color: #212121;
  content: "";
  top: -20px;
}

.menu .icon:after {
  transition-duration: 0.5s;
  position: absolute;
  width: 60px;
  height: 8px;
  background-color: #212121;
  content: "";
  top: 20px;
}

.menu.open .icon {
  transition: 0.5s;
}

.menu.open .icon:before {
  transform: rotateZ(-45deg) scaleX(0.75) translate(-20px, -6px);
}

.menu.open .icon:after {
  transform: rotateZ(45deg) scaleX(0.75) translate(-20px, 6px);
}

.menu:hover {
  cursor: pointer;
}

#home-img-box img {
  width: 100%;
}

@media only screen and (min-width: 800px) {
  #home-img-box img {
    width: 40%;
  }
}

#contact-table {
  margin: 15px auto;
  padding: 15px;
  border-radius: 20px;
}

#contact-table tr>td {
  padding: 0 0 5px 10px;
}

#openinghours-table {
  width: 100%;
  text-align: left;
  /* margin: 15px auto; */
  padding: 15px;
}

#openinghours-table th {
  text-align: right;
  width: 50%;
}

#openinghours-table td {
  width: 50%;
}

#closingdays-table {
  width: 100%;
  text-align: left;
  /* margin: 15px auto; */
  padding: 0 15px 0 15px;
}

#closingdays-table th {
  text-align: right;
  width: 50%;
}

#closingdays-table:not(:first-child) th h1 {
  padding-top: 15px;
}

#closingdays-table td {
  width: 50%;
}

#openinghours-tables {
  padding: 25px 0;
  display: flex;
  flex-wrap: wrap;
}

#openinghours,
#closingDays {
  max-width: 80vw;
  min-width: 40vw;
  margin: auto;
}


#openinghours-table td {
  color: rgb(26, 109, 37);
  font-weight: bold;
}


#openinghours-table .closed {
  color: rgb(146, 3, 3);
  font-weight: bold;
}

.title {
  text-align: center !important;
}


@media only screen and (max-width: 599px) {

  #openinghours,
  #closingDays {
    width: 100vw;
    margin: auto;
  }

  tr {
    display: grid;
  }

  tr th {
    width: 80vw !important;
  }

  td {
    padding: 0;
  }

  #openinghours-table,
  #openinghours-table th {
    text-align: left;
    padding: 0;
  }

  #closingdays-table,
  #closingdays-table th {
    text-align: left;
    padding: 0;
  }

  #contact-table,
  #contact-table th {
    text-align: left;
    padding: 0;
  }

  #closingdays-table {
    padding-top: 50px;
  }

  .title {
    text-align: left !important;
  }
}

#google-maps {
  width: 80%;
  height: 500px;
  margin: 0 auto;
  display: block;
  padding: 30px;
}




/* Timeline */
.timeline-centered {
  position: relative;
  margin-top: 140px;
  bottom: 120px;
}

.timeline-centered.timeline-sm .timeline-entry {
  margin-bottom: 0px !important;
}

.timeline-centered.timeline-sm .timeline-entry .timeline-entry-inner .timeline-label {
  padding: 1em;
  border-radius: 20px;
}

.timeline-centered:before,
.timeline-centered:after {
  content: " ";
  display: table;
}

.timeline-centered:after {
  clear: both;
}

.timeline-centered:before {
  content: '';
  position: absolute;
  display: block;
  width: 7px;
  background: #aaa;
  left: 50%;
  top: 20px;
  bottom: -70px;
  margin-left: -4px;
}

.timeline-centered .timeline-entry {
  position: relative;
  width: 50%;
  float: right;
  margin-bottom: 70px;
  clear: both;
}

@media screen and (max-width: 800px) {
  .timeline-centered:before {
    left: 40px;
  }

  .timeline-centered .timeline-entry {
    float: none;
    padding: 40px;
    width: calc(100% - 60px);
  }
}

.timeline-centered .timeline-entry:before,
.timeline-centered .timeline-entry:after {
  content: " ";
  display: table;
}

.timeline-centered .timeline-entry:after {
  clear: both;
}

.timeline-centered .timeline-entry.begin {
  margin-bottom: 0;
}

@media screen and (min-width: 800px) {
  .timeline-centered .timeline-entry.left-aligned {
    float: left;
  }
  
  .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner {
    margin-left: 0;
    margin-right: -32px;
  }
  
  .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-time {
    left: auto;
    right: -115px;
    text-align: left;
  }
  
  .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-icon {
    float: right;
  }
  
  .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label {
    margin-left: 0;
    margin-right: 85px;
  }
  
  .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label:after {
    left: auto;
    right: 0;
    margin-left: 0;
    margin-right: -9px;
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.timeline-centered .timeline-entry .timeline-entry-inner {
  position: relative;
  margin-left: -32px;
}

.timeline-centered .timeline-entry .timeline-entry-inner:before,
.timeline-centered .timeline-entry .timeline-entry-inner:after {
  content: " ";
  display: table;
}

.timeline-centered .timeline-entry .timeline-entry-inner:after {
  clear: both;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time {
  position: absolute;
  left: -115px;
  text-align: right;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time>span {
  display: block;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time>span:first-child {
  font-size: 18px;
  font-weight: bold;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time>span:last-child {
  font-size: 12px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon {
  background: #fff;
  color: #999999;
  display: block;
  width: 60px;
  height: 60px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  border-radius: 50%;
  text-align: center;
  border: 7px solid #ffffff;
  line-height: 45px;
  font-size: 15px;
  float: left;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-primary {
  background-color: #dc6767;
  color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-success {
  background-color: #5cb85c;
  color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-info {
  background-color: #5bc0de;
  color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-warning {
  background-color: #f0ad4e;
  color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-danger {
  background-color: #d9534f;
  color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-red {
  background-color: #DEA6A7;
  border: 2px solid #bf4346;
  color: #333;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-green {
  background-color: #488c6c;
  color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-blue {
  background-color: #CEE0E0;
  border: 2px solid #0a819c;
  color: #333;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-yellow {
  background-color: #F7E0C1;
  border: 2px solid #f2994b;
  color: #333;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-orange {
  background-color: #e9662c;
  color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-pink {
  background-color: #bf3773;
  color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-violet {
  background-color: #9351ad;
  color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-grey {
  background-color: #4b5d67;
  color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-dark {
  background-color: #594857;
  color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label {
  position: relative;
  background: #ffffff;
  padding: 1.7em;
  margin-left: 85px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-red {
  background-color: #DEA6A7;
  border: 2px solid #bf4346;
  color: #333;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-red:after {
  border-color: transparent #bf4346 transparent transparent;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-red .timeline-title,
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-red p {
  color: #333;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-green {
  background-color: #CEF7C5;
  border: 2px solid #488c6c;
  color: #333;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-green:after {
  border-color: transparent #488c6c transparent transparent;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-green .timeline-title,
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-green p {
  color: #333;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-orange {
  background: #e9662c;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-orange:after {
  border-color: transparent #e9662c transparent transparent;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-orange .timeline-title,
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-orange p {
  color: #ffffff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-yellow {
  background-color: #F7E0C1;
  border: 2px solid #f2994b;
  color: #333;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-yellow:after {
  border-color: transparent #f2994b transparent transparent;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-yellow .timeline-title,
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-yellow p {
  color: #333;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-blue {
  background-color: #CEE0E0;
  border: 2px solid #0a819c;
  color: #333;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-blue:after {
  border-color: transparent #0a819c transparent transparent;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-blue .timeline-title,
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-blue p {
  color: #333;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-pink {
  background: #bf3773;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-pink:after {
  border-color: transparent #bf3773 transparent transparent;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-pink .timeline-title,
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-pink p {
  color: #ffffff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-violet {
  background: #9351ad;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-violet:after {
  border-color: transparent #9351ad transparent transparent;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-violet .timeline-title,
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-violet p {
  color: #ffffff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-grey {
  background: #4b5d67;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-grey:after {
  border-color: transparent #4b5d67 transparent transparent;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-grey .timeline-title,
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-grey p {
  color: #ffffff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-dark {
  background: #594857;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-dark:after {
  border-color: transparent #594857 transparent transparent;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-dark .timeline-title,
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-dark p {
  color: #ffffff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label:after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 9px 9px 0;
  border-color: transparent #ffffff transparent transparent;
  left: 0;
  top: 20px;
  margin-left: -9px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label .timeline-title,
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p {
  color: #999999;
  margin: 0;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p+p {
  margin-top: 15px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label .timeline-title {
  margin-bottom: 10px;
  font-weight: bold;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label .timeline-title span {
  -webkit-opacity: .6;
  -moz-opacity: .6;
  opacity: .6;
  -ms-filter: alpha(opacity=60);
  filter: alpha(opacity=60);
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p .timeline-img {
  margin: 5px 10px 0 0;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p .timeline-img.pull-right {
  margin: 5px 0 0 10px;
}

/* .timeline-icon i.fa {
  font-size: 30px;
  margin-top: 14px;
} */

.timeline-icon {
  position: relative;
}

.timeline-icon time {
  position: absolute;
  top: 0;
  left: 0;
}

.timeline-icon time strong {
  position: absolute;
  top: 7px;
  left: 11px;
}


/*Media*/
.yarl__thumbnails_container nav {
  top: unset !important;
  bottom: 0;
  background-color: black;
}

#ReactGridGallery {
  display: grid;
}

#ReactGridGallery div {
  justify-content: center;
}

#home-img-box {
  padding: 50px 0;
}

#home-img-box #ReactGridGallery div div div img {
  border-radius: 100px;
}

#contact-table a {
  color: black;
}

.ReactGridGallery_tile {
  background-color: transparent !important;
}